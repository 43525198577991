import React, { Component } from 'react';
import Home from "./pages/Home/Home"
import './App.css';

class App extends Component {
  render() {
  return (
    <div>
      <Home />
  </div>
  );
}
}

export default App;
